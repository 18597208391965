import React from "react"
import Layout from "@components/layout"
import Seo from "@components/seo"
import SolutionsSidebar from "@components/solutionsSidebar"
import { graphql } from "gatsby"

const WebSolutions = ({ data }) => (
  <Layout>
    <Seo
      title="Web Solutions"
      description={data.site.siteMetadata.description}
    />
    <div className="page-headline">
      <div className="container">
        <h6 className="text-white-50 text-uppercase">Solutions</h6>
        <h1>Web Solutions</h1>
      </div>
    </div>
    <div className="page-content bg-light">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-7 col-lg-8">
            <p>
              Imagine someone else handling all of your hosting, email and
              networking functions so that you never had to log into a server.
              That's what we provide. And we're only one simple phone call away,
              giving you full control of your hosting while we take care of the
              details.
            </p>
            <p>
              On top of that, we offer a 99.9% uptime guarantee. If your site is
              unavailable because of a problem with our systems, you won't pay
              for it. We understand how important it is to keep your customers
              happy, and we'll respond whenever and in whatever means necessary
              to ensure that.
            </p>
            <p>
              We also offer premium add-on services that'll take your website
              and your business to the next level. From advanced email spam and
              virus filtering to our signature update service, it's like having
              a full-time webmaster for a fraction of the cost.
            </p>
            <ul>
              <li>24/7 server monitoring</li>
              <li>Server firewall with intrusion detection and prevention</li>
              <li>Spamassassin email anti-spam and ClamAV email anti-virus</li>
              <li>Hands-free site management</li>
              <li>Unlimited web, email and phone support</li>
            </ul>
            <h4>White Label Hosting</h4>
            <p>
              Would you like to offer your own web hosting services to your
              clients? We have several plans available for web designers and
              marketing specialists. Call us today to learn more.
            </p>
            <ul>
              <li>Your own branding</li>
              <li>Unlimited domains</li>
              <li>Professional support</li>
              <li>99.9% uptime guarantee</li>
              <li>Unlimited MySQL databases</li>
              <li>Unlimited emails and aliases</li>
              <li>WebHost Manager (WHM) Demo</li>
              <li>cPanel Control Panel Demo</li>
            </ul>
            <h4>Website Development</h4>
            <p>
              Your website should be an asset to your company that provides
              tools to earn you money, to save you and your customers time, and
              to simplify your life.
            </p>
            <p>
              If you only think of your website as an expense, you have the
              wrong web developer. Before we write a line of code, we want to
              learn about your business, your customers, and your goals. How do
              you envision your customers interacting with your website a few
              years from now? What difficulties have you experienced on your own
              or others' websites? We'll help you create a marketing platform
              that will grow along with your business.
            </p>
            <p>
              We can also automate some of your business practices to free up
              you and your staff for other tasks, to create reporting tools
              that'll deliver the metrics you need to make good business
              decisions, and much more. There's no limit to the ways we can put
              your website to work for you.
            </p>
            <h4>
              We build much more than web sites. Here are a few of the web
              applications we have developed:
            </h4>
            <ul>
              <li>Shopping Carts and eCommerce</li>
              <li>Custom Booking Systems</li>
              <li>Portfolios and Galleries</li>
              <li>Social Media Platforms</li>
              <li>Infrastructure Monitoring</li>
              <li>Data Analysis and Reporting</li>
              <li>Custom Payment Systems</li>
              <li>User and Staff Management</li>
              <li>Security and Access Control</li>
              <li>Membership and Communities</li>
            </ul>
          </div>
          <div className="col-md-4 col-lg-3">
            <SolutionsSidebar />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default WebSolutions

export const WebSolutionsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
